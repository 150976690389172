.container.contact__container {
    width: 80%;
    align-items: center;
    justify-content: center;
}

.contact__form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: var(--color-primary-variant);
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

::placeholder {
    color: white;
}

.send-msg-container{
    text-align: center;
}